// if you need to add custom js put it in here
$(window).load(function () {
    $('.HButton').bootstrapSwitch();
    $('.about-content-wrapper').matchHeight();
    $('.blogTitle').matchHeight();
    $('.top-footer-icon-heading').matchHeight();
    $('.pf-recent-related-product-item.product-match-height').matchHeight();
    FixTopPaddingPromotionStrip();

    $('.nav-menu.w-nav-menu .w-dropdown').hover(function (evt) {
        evt.preventDefault();
        var openClass = 'w--open';
        if (evt.type == 'mouseenter') {
            $(this).find('.w-dropdown-toggle').addClass(openClass);
            $(this).find('.w-dropdown-list').addClass(openClass);
        }
        else if (evt.type == 'mouseleave') {
            $(this).find('.w-dropdown-toggle').removeClass(openClass);
            $(this).find('.w-dropdown-list').removeClass(openClass);
        }
    });

    $('.search-opener').click(function () {
        $(window).scrollTop(0);
        $('#search-div-block-compact').show();
        $('.logo-section').hide();
    });

    $('.search-close').click(function () {
        if (document.body.scrollTop < 50 || document.documentElement.scrollTop < 50) {
            $('#search-div-block-compact').hide();
            $('.logo-section').show();
        }
    });


    $('.back-to-top-link-block').click(function () {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });
    $('.downloads-widget').click(function (e) {
        e.preventDefault();
        //var childElemOpened = $(this).find(".faq-dropdown-list.w--open");
        //if (childElemOpened != null) {
        //    var height = $(this).siblings().height();
        //    var wrapperHeight = $(this).parent().parent().height();
        //    $(this).parent().parent().css('height', height+wrapperHeight);
        //}
        //else
        //{
        //    $(this).parent().parent().css('height', '100%');
        //    }
    });

    $('.hamburger-menu-button').click(function () {
        console.log($(this).is('.w--open'));
        if ($(this).is('.w--open')) {
            $('.pf-promotion-container').hide()
        } else {
            $('.pf-promotion-container').show()
        }

    });

    $('.bag-opener-smallnav').click(function () {
        switch (pf_DisplayCartOption) {
            case ("slide"): {
                SlideTopCart();
                break;
            }
            case ("popup"): {
                OpenCartPopup();
                break;
            }
            case ("page"): {
                window.location.href = root + "Checkout";
                break;
            }
        }
        return false;
    });
    $(document).on('change', '#search-product-sort', function () {
        const searchBox = $("#expanded-search-tbx");
        var searchUrl = searchBox.data("search-url");
        var searchStr = searchBox.val();
        var sort = $('#search-product-sort').val();
        searchUrl += "?search=" + encodeURIComponent(searchStr);
        if (typeof sort != 'undefined') {
            searchUrl += "&listby=" + sort;
        }


        gtag('config', $('#GoogleAnalyticsID').data('id'), {
            'page_title': 'Virtual search',
            'page_path': '/virtual/search?search=' + encodeURI(searchStr)
        });
        if (searchStr.length >= 2) {
            GetSearchResults(searchUrl);
            $('#search-string-display').html(searchStr);
        } else {
            $('#search-string-display').html("");
            $('#search-results-wrapper').html("");
        }
    });
    $(document).on('change', '#basket-sort', function () {
        var sort = $('#basket-sort').val();
        $('.pf-page-cart-loader').load(root + 'cart/GetCart?forCheckout=true&sortBy='+sort, function () {

        });
    });
    function GetSearchResults(searchUrl) {
        $('#search-results-spinner-wrapper').show()
        $.ajax({
            url: searchUrl,
            type: 'GET',
            contentType: 'application/json; charset=utf-8',
            success: function (data) {
                $('#search-results-wrapper').html(data);
                $('#search-results-wrapper').waitForImages(function () {
                    $('.sub-cat-holder-match-height').matchHeight();
                    $('.product-info-match-height').matchHeight();
                });
                InitSearchResultEvents();
                $('#search-results-spinner-wrapper').hide()
            },
            error: function (msg) {
                alert('Sorry there was a problem processing your request.');
            }
        });
    }
    window.onscroll = function () { scrollFunction() };
    function scrollFunction() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            $('#search-div-block-compact').show();
            $('.logo-section').hide();
        } else {
            if (!$('.search-section').is(':visible')) {
                $('#search-div-block-compact').hide();
                $('.logo-section').show();
            }
        }
    }


    // additional mac/ios specific styling required. add the class mac-os to the required element
    if (navigator.userAgent.indexOf('Mac') > 0) {
        $('.pf-new-product-tag').each(function () {
            $(this).find('span').addClass('mac-os')
        });
        $('.pf-sale-product-tag').each(function () {
            $(this).find('span').addClass('mac-os')
        });
    }

    $('.downloads-dropdown .faq-dropdown-list .mbss-listItem2-image-wrapper a.mbss-listItem-link').each(function () {
        $(this).attr('download', '');
    });

    function InitSearchResultEvents() {
        $(".pf-load-more-search-results-btn").click(function () {
            const loadMoreUrl = $(this).data("url");
            GetSearchResults(loadMoreUrl);
        });
    }

    function FixTopPaddingPromotionStrip() {
        var $website = $('div.the-website');
        if ($website.hasClass('pf-promotion-strip-enabled')) {
            var $header = $('header.header');
            var $headerLogoSection = $header.find('.logo-section');
            var topPadding = $header.outerHeight();
            if (!$headerLogoSection.is(':visible'))
                topPadding += $headerLogoSection.outerHeight();
            $website.css('padding-top', topPadding);
        }
    }
    $(window).resize(function () {
        FixTopPaddingPromotionStrip();
    });
});

